import React from 'react';
import {  Row, Col } from 'antd';
import "antd/dist/antd.css";
import './App.css';
import logo from '../images/logo.png';
import headerImage from '../images/header-image.png';



function Header() {
  return (

        <Row className='header'>
          <Col xs={24} sm={24} lg={12}>
            <div className="site-title">
              <div className="logo"><img src={logo} /><span className="tech">TechCoach</span></div>
              <div className="slogan">Schedule your session with a TechCoach</div>
            </div>
          </Col>
          <Col xs={24} sm={24} lg={12} className="site-banner">
            <img src={headerImage} />
          </Col>
        </Row>

  );
}

export default Header;
