import React from 'react';
import { Layout,  Row, Col, Button } from 'antd';
import "antd/dist/antd.css";
import './Summary.css';
import Header from './Header';
import iconClock from '../images/icon-clock.png';
import iconPhone from '../images/icon-phone.png';
import iconCalendar from '../images/icon-calendar.png';
import iconMobile from '../images/icon-mobile.png';
import iconChannel from '../images/icon-channel.png';
import iconProvider from '../images/icon-provider.png';
import iconReason from '../images/icon-reason.png';
import iconTechnology from '../images/icon-technology.png';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

const { Footer, Sider, Content } = Layout;

function Summary() {
  const location = useLocation<any>();
  const booking = location.state.booking;
  /*const booking={
    data:{
      BookingDate: '',
      BookingTime:'',
      SessionReason:'',
      "Mobile":'',
      "InternetProvider": '',
      "InternetTech": '',
      "OtherAssistance": '',
      "ExpectedAdvice": '',
      "Channel": ''
    }
  }*/


  ReactGA.initialize('UA-175457162-1');
  ReactGA.pageview('/summary');

  console.log(booking);
  return (
    <div className="App">
      <Layout>
      <Content className="page">
        <Header />
        <Row>
          <Col xs={24} sm={24} lg={{ span: 12, offset: 6 }} className="summary-section">
              <div className="summary-info">You're all set!</div>
              <div className="summary-subinfo">You’ve successfully scheduled your TechCoach session</div>
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }} className="summary">
                  <Row className="summary-detail">Below are the details of your session!</Row>
                  <Row className="summary-subdetail">We will also send you an email with details below. Please check your email spam folder if you can’t see this email within a couple of hours.</Row>
                  <Row className="summary-section">
                  <Col  className="summary-block">
                        <img src={iconChannel} />
    
                        <div>
                          <div className="sub-title">
                              Channel
                          </div>
                          <div className="sub-detail">{booking.data.Channel}</div>
                        </div>
                      </Col>

                      <Col  className="summary-block">
                        <img src={iconCalendar} />
    
                        <div>
                          <div className="sub-title">
                              Scheduled Date
                          </div>
                          <div className="sub-detail">{booking.data.BookingDate}</div>
                        </div>
                      </Col>

                      <Col  className="summary-block">
                        <img src={iconClock} />
          
                      <div>
                        <div className="sub-title">
                            Scheduled Time
                        </div>
                        <div className="sub-detail">{booking.data.BookingTime}</div>
                      </div>
                      </Col>
                      <Col  className="summary-block">
                        <img src={iconPhone} />
                 
                      <div>
                          <div className="sub-title">
                            Mobile Number
                          </div>
                          <div className="sub-detail">{booking.data.Mobile}</div>
                      </div>
                      </Col>
 
                   <Col  className="summary-block">
                        <img src={iconReason} />
                 
                      <div>
                          <div className="sub-title">
                            Reason for a session
                          </div>
                          <div className="sub-detail">{booking.data.SessionReason}</div>
                      </div>
                      </Col>
                      <Col  className="summary-block">
                        <img src={iconProvider} />
                 
                      <div>
                          <div className="sub-title">
                            Internet Provider
                          </div>
                          <div className="sub-detail">{booking.data.InternetProvider}</div>
                      </div>
                      </Col>

                      <Col className="summary-block">
                        <img src={iconTechnology} />
                 
                      <div>
                          <div className="sub-title">
                            Internet Technology
                          </div>
                          <div className="sub-detail">{booking.data.InternetTech}</div>
                      </div>
                      </Col>
                  </Row>
              
                  <Row className='btn-zone'>



                </Row>

              </Col>

            </Col>
        </Row>

      </Content>
      </Layout>
    </div>
  );
}

export default Summary;
