import React from 'react';
import {  Row, Col } from 'antd';
import "antd/dist/antd.css";
import './App.css';
import asurionSpinner from '../images/asurion-spinner.gif';



function Spinner() {
  return (
    <div>
        <div className="ant-modal-mask loading"></div>
        <div className="ant-modal-wrap">
            <div className="image">
                <img src={asurionSpinner} />
                <p>Please wait, this shouldn't take long</p>
            </div>
        </div>
    </div>
  );
}

export default Spinner;
