import React, { useState, useMemo, useEffect } from 'react';
import { Layout,  Form, Input, Button, Radio, Select, Row, Col, Modal, message, Checkbox, Spin, Switch, Alert} from 'antd';
import { CalendarOutlined, ClockCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import "antd/dist/antd.css";
import './App.css';
import Header from './Header';
import Spinner from './Spinner';
import axios from 'axios';
import moment from 'moment';
import { useHistory, Redirect, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga';


const { Footer, Sider, Content } = Layout;
const { confirm } = Modal;

const recaptchaRef = React.createRef<any>();

const options = [
  { label: 'Internet Call (VoIP)', value: 'voice' },
  { label: 'Video Call', value: 'video' },
];



function App() {

  interface Dic {
    [key: string]: Booking[]
  }

  interface Booking {
    Id:string;
    BookingDate:string,
    BookingTimeSlot:string,
    Channel:string
  }
  const history = useHistory();

  const [ mobile, setMobile] = useState('');
  const [ provider, setProvider] = useState('');
  const [ technology, setTechnology] = useState('');
  const [ assistance, setAssistance] = useState('');
  const [ advice, setAdvice] = useState('');
  const [ channel, setChannel] = useState('');
  const [ reason, setReason] = useState('');
  const [ dateData, setDateData ] = useState<Array<string>>([]);
  const [ times, setTimes ] = useState<Array<Booking>>([]);
  const [ timeData, setTimeData] = useState<Dic>({});
  const [ date, setDate] = useState('');
  const [ time, setTime] = useState('');
  const [ timeSlot, setTimeSlot] = useState('');
  const [ loading, setLoading] = useState(false);
  const [ showCaptcha, setShowCaptcha] = useState(false);
  const [ agree, setAgree] = useState(false);
  const [form] = Form.useForm();
  

  const changeValue = (name:string, event:any) => {
    switch(name) {
      case 'mobile':
        setMobile(event.target.value);
        return;
      case 'assistance':
        setAssistance(event.target.value);
        return;
      case 'advice':
        setAdvice(event.target.value);
        return;
      case 'channel':
        setChannel(event.target.value);
        getSlots(event.target.value);
        return;
    }

  };

  const getSlots = (channel:string) =>{
    setDateData([]);
    setDate('');
    setTimes([]);
    setTime('');
    setTimeSlot('');
    form.setFieldsValue({
        date: undefined,
        time: undefined
    });

    axios.get(process.env.REACT_APP_API_URL+'/api/techcoach/v1/slots?channel='+channel, { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } })
    .then(res => {
      let result = res.data;

      if(result.slots){
        let temp=[];
        for (const [key, value] of Object.entries(result.slots)) {
          //setDateData(dateData => [...dateData, key]);
          temp.push(key);
          console.log();
        }
        console.log(temp);
        setDateData(temp);
        setTimeData(result.slots);
        console.log(result.slots);
      }else{

        console.log('y');
      }
    }).catch(function (err) {

      console.log('z');
  });;
  }

  const changeDate = (value:any) => {
    setDate(value);
    //console.log(value);
    //console.log(timeData);
    console.log('timeData[value]',timeData[value]);
    setTimes(timeData[value]);
    //console.log('times',times);
  }

  const changeTime = (value:any) => {
    setTimeSlot(value);
    times.forEach(t=>{
      console.log(t.Id);
      if(t.Id==value){
        setTime(t.BookingTimeSlot);
      }
    })
    console.log(value);
    console.log(time);
    console.log(timeSlot);
  }

  const agreeTnc = (e:any) => {
    console.log('checked = ', e.target.checked);
    setAgree(e.target.checked)
  }

  const changeRecaptcha = (value:any) => {
    console.log(value);
    setShowCaptcha(false);
  }
  const toTerm = (event:any) => {
    event.preventDefault();
    history.push("/term");
  }

  const onFinish = (values:any) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if(!recaptchaValue){
      console.log("no value");
      setShowCaptcha(true);
      return;
    }else{
      console.log(recaptchaValue);
    }

    confirm({
      title: 'Would you like to confirm your booking?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        

        const requestBody={
          "data": {
              "Mobile": mobile,
              "SessionReason": reason,
              "SlotId": timeSlot,
              "InternetProvider": provider,
              "InternetTech": technology,
              "OtherAssistance": assistance,
              "ExpectedAdvice": advice,
              "Channel": channel,
              "BookingDate":date,
              "BookingTime":time        
          }
        }
        console.log(requestBody);
        setLoading(true);

        axios({
          method: 'post',
          url: process.env.REACT_APP_API_URL+'/api/techcoach/v1/bookings/create',
          data: requestBody,
          headers: {'Content-Type': 'appilication/json', 'x-api-key': process.env.REACT_APP_API_KEY }
        })
        .then(function (response:any) {
          response=response.data;
          if(!response.booking.Error){

            let param={
              "data": {
                  "SessionReason": reason,
                  "BookingTime":time,
                  "BookingDate": date,
                  "Mobile":mobile,
                  "InternetProvider": provider,
                  "InternetTech": technology,
                  "OtherAssistance": assistance,
                  "ExpectedAdvice": advice,
                  "Channel": channel
              }
            }
            console.log(param);
            history.push("/summary", { booking: param });

            setLoading(false);
          }else{
              setLoading(false);
              message.error('Your mobile number is not enrolled to Asurion TechCoach program, please verify!');
          }
          
        })
        .catch(function (response) {
            console.log(response);
        });
        
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  };
  
  const cancel = (event:any) =>{
    console.log(event);
    //message.error('Click on No');
  }

  ReactGA.initialize('UA-175457162-1');
  ReactGA.pageview('/home');

  const recaptcha = process.env.REACT_APP_RECAPTCHA!;
  return (
    <div className="App">
      <Layout>
      <Content className="page">
        <Header />
        <Row>
          <Col xs={24} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} className="info">Please choose your preferred schedule for a Tech Expert session</Col>
        </Row>
        <Row>

          <Col xs={24} sm={24} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
       
            <Form hideRequiredMark onFinish={onFinish} form={form}>

              <Row>
                <Col xs={24} style={{paddingLeft:10, marginBottom:10}}>
                  <Form.Item labelCol={{ span: 24 }} className='radio' name="channel" label="Select calling options *" rules={[{ required: true, message: 'Please choose a calling option!' }]}>
                    <Radio.Group options={options} value={channel} onChange={(event) => {changeValue('channel', event)}} />
                  </Form.Item>
                </Col>

              </Row>

              <Row>
                <Col xs={24} sm={12} className="form-row">
                  <Form.Item labelCol={{ span: 24 }} name="date" label="Date of Session *" rules={[{ required: true, message: 'Please select a date!' }]}>
                    <Select
                      placeholder="Select Date"
                      onChange={changeDate} 
                      value={date}
                      suffixIcon={<CalendarOutlined />}
                    >
                      {dateData.map(d => (
                        <Select.Option key={d} value={d}>{d} {moment(d).format('dddd')}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="form-row">
                  <Form.Item labelCol={{ span: 24 }} name="time" label="Time of Session (AEST) *" rules={[{ required: true, message: 'Please select a slot!' }]}>
                    <Select
                      placeholder="Select Time"
                      value={time} 
                      onChange={changeTime} 
                      suffixIcon={<ClockCircleOutlined />}
                    >
                      {times.map(d => (
                        <Select.Option key={d.Id} value={d.Id}>{d.BookingTimeSlot}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} className="form-row">
                  <Form.Item
                    name="mobile" 
                    label="Mobile Number *" 
                    rules={[{ required: true, message: 'Please input your mobile number!' },{
                      type: 'number',
                      message: 'Please enter a valid mobile number!',
                      transform(value) {
                      return Number(value);
                      }
                    }, { min:10, message: 'Mobile number must be 10 digits'},{ max:10, message: 'Mobile number must be 10 digits'}]}
                    labelCol={{ span: 24 }}
                  >
                    <Input placeholder="e.g. 1234567890" value={mobile} onChange={(event) => {changeValue('mobile', event)}} />
                  </Form.Item>
                </Col>

                <Col span={24} sm={12} className="form-row">
                  <Form.Item labelCol={{ span: 24 }} name="reason" label="Reason for a session *" rules={[{ required: true, message: 'Please select a reason!' }]}>
                    <Select
                      placeholder="Select"
                      allowClear 
                      value={reason} 
                      onChange={value => {
                        setReason(value)
                      }}
                    >
                      <Select.Option value="Home office support">Home Office Support</Select.Option>
                      <Select.Option value="Smart Home Support">Smart Home Support</Select.Option>
                      <Select.Option value="Streaming & Entertainment">Streaming & Entertainment</Select.Option>
                      <Select.Option value="Purchasing Guidance">Purchasing Guidance</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} className="form-row">
                <Form.Item labelCol={{ span: 24 }} name="provider" label="Internet provider *" rules={[{ required: true, message: 'Please select one internet provider!' }]} >
                    <Select
                      placeholder="Select"
                      allowClear 
                      
                      value={provider} 
                      onChange={value => {
                        setProvider(value)
                      }} 
                    >
                      <Select.Option value="iiNet">iiNet</Select.Option>
                      <Select.Option value="Optus">Optus</Select.Option>
                      <Select.Option value="Telstra">Telstra</Select.Option>
                      <Select.Option value="TPG">TPG</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} className="form-row">
                  <Form.Item labelCol={{ span: 24 }} name="technology" label="Internet technology *" rules={[{ required: true, message: 'Please select one technology!' }]}>
                    <Select
                      placeholder="Select"
                      allowClear 
                      value={technology} 
                      onChange={value => {
                        setTechnology(value)
                      }}
                    >
                      <Select.Option value="ADSL">ADSL</Select.Option>
                      <Select.Option value="Cable">Cable</Select.Option>
                      <Select.Option value="NBN">NBN</Select.Option>
                      <Select.Option value="5G/4G Wireless">5G/4G Wireless</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}  className="form-row">
                  <Form.Item
                    name="assistance" 
                    label="Is there any Internet or Smart Home equipment you especially would like to have assistance with or learn more of during your coaching session?" 
                    rules={[{ required: false}, { max:255, message: 'Maximum 255 characters!'}]}
                    labelCol={{ span: 24 }}
                    className="assitant-note" 
                  >
                    <Input placeholder="" value={assistance}  onChange={(event) => {changeValue('assistance', event)}} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}  className="form-row">
                <Form.Item
                    name="advice" 
                    label="What type of advice and recommendations do you expect to receive as part of the session?" 
                    rules={[{ required: false}, { max:255, message: 'Maximum 255 characters!'}]}
                    labelCol={{ span: 24 }}
                    className="advice-note" 
                  >
                    <Input placeholder="" value={advice}  onChange={(event) => {changeValue('advice', event)}} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="recaptcha">
                <Col xs={24} sm={24} lg={24}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptcha} onChange={changeRecaptcha} />
                </Col>
             {showCaptcha && <div className="error">Please verify you are humann! </div> }
              </Row>
              <Row>
              <Col xs={24} sm={24} lg={24} className="tnc-placeholder">
                <Checkbox onChange={agreeTnc} >By Scheduling a Tech Coach session, you acknowledge 
you have read and accepted the <a href="/term" target="_blank">Tech Coach Terms of Use</a>.</Checkbox>
                </Col>

              </Row>
            <Row style={{ textAlign:'center', display:'block' }}>

              <Form.Item>
                {agree? 
                <Button type="default" className="submit-btn" htmlType="submit">
                  Schedule me a session
                </Button>:
                <Button type="default" disabled className="submit-btn" htmlType="submit">
                Schedule me a session
              </Button>
                }
              </Form.Item>
        
            </Row>


          </Form>
            
          </Col>
 
        </Row>
 
      </Content>
      </Layout>
      {loading && <Spinner />}
   
    </div>
  );
}

export default App;
